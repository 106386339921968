//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setBannerItemAttrLink } from '@/helpers'
export default {
  name: 'BannerLocationHeroItem',
  props: {
    device: {
      type: String,
      default: null
    },
    lazyImage: {
      type: Boolean,
      default: false
    },
    lastUpdate: {
      type: Date,
      default: null
    },
    item: {
      type: Object,
      default: Object
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  // serverCacheKey: props => `HeroBannerItem::${props.device}::${props.item?.bannerItemUuid ?? ''}::${props.lastUpdate?.toISOString()}`,
  computed: {
    imageOfBanner () {
      return this.item?.bannerImage ?? {}
    }
  },
  methods: {
    setBannerItemAttrLink,
    handleClickSlideItem (event) {
      this.$emit('onClickItem', event)
    }
  }
}
