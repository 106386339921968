//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setBannerItemAttrLink, cloneObject } from '@/helpers'
import { aspectRatio } from '@/helpers/image'
import { deviceSizes } from '@/config/core'
function mergeBannerImage (imageList, onFoundExistingItem) {
  const list = imageList.reduce((acc, obj) => {
    const existingObj = acc.find(item => item.size === obj.size)
    if (existingObj) {
      Object.assign(existingObj, obj)
      onFoundExistingItem()
    } else {
      acc.push(obj)
    }
    return acc
  }, [])
  return list
}
export default {
  name: 'BannerLocationHero',
  props: {
    bannerData: {
      type: Object,
      default: Object
    }
  },
  data () {
    const device = this.$device.isMobile ? 'Mobile' : 'Desktop'
    return {
      device,
      loading: true,
      bannerDataList: []
    }
  },
  computed: {
    lastUpdate () {
      if (this.bannerData?.lastUpdate) {
        return new Date(this.bannerData.lastUpdate)
      }
      return this.bannerData?.lastUpdate
    },
    bannerSize () {
      let height = this.bannerData?.mobileHeight
      let width = this.bannerData?.mobileWidth
      if (this.$device.isMobile === false) {
        height = this.bannerData?.height
        width = this.bannerData?.width
      }
      return {
        height,
        width
      }
    },
    autoplay () {
      if (this.bannerData.transitionSec) {
        return {
          delay: this.bannerData.transitionSec * 1000
        }
      }
      return false
    },
    bannerList () {
      const bannerItems = cloneObject(this.bannerData.bannerItems || [])
      const newBannerItems = bannerItems.map((item) => {
        let bannerImage = item.desktopImage
        if (this.$device.isMobile === true) {
          bannerImage = item.mobileImage
        }
        const obj = {
          ...item,
          isMobile: this.$device.isMobile,
          bannerImage
        }
        delete obj.desktopImage
        delete obj.mobileImage
        return obj
      })
      return newBannerItems
    },
    cssAspectRatio () {
      const { width, height } = this.bannerSize
      const aspect = aspectRatio(width, height)
      return `aspect-ratio: ${aspect[0]}/${aspect[1]};`
    },
    swiperOption () {
      const showNavigation = this.bannerData.controlArrow && this.bannerList.length > 1
      const option = {
        watchOverflow: true,
        loop: this.bannerList.length > 1,
        autoplay: this.autoplay,
        showNavigation,
        pagination: false,
        navigation: {
          nextEl: '.hero-banner .swiper-btn-next',
          prevEl: '.hero-banner .swiper-btn-prev'
        }
      }
      return option
    }
  },
  created () {
    this.mergeBannerImage()
  },
  mounted () {
    this.registerEvent()
    this.setAspectRatio()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    registerEvent () {
      window.addEventListener('resize', this.handleWindowResize)
    },
    removeEvent () {
      window.removeEventListener('resize', this.handleWindowResize)
    },
    handleWindowResize () {
      this.setAspectRatio()
    },
    setAspectRatio () {
      const { width, height } = this.getBannerSize()
      const aspect = aspectRatio(width, height)
      const heroBannerWrapper = this.$refs.heroBannerWrapper
      if (heroBannerWrapper) {
        heroBannerWrapper.style.aspectRatio = `${aspect[0]}/${aspect[1]}`
      }
    },
    getBannerSize () {
      let height = this.bannerData?.mobileHeight
      let width = this.bannerData?.mobileWidth
      if (window.innerWidth > deviceSizes.sm) {
        height = this.bannerData?.height
        width = this.bannerData?.width
      }
      return {
        height,
        width
      }
    },
    mergeBannerImage () {
      const bannerItems = cloneObject(this.bannerData.bannerItems || [])
      const dataList = []
      bannerItems.forEach((item) => {
        const {
          desktopImage: { alt: altPC, images: imagePC },
          mobileImage: { alt: altMobile, images: imageMobile }
        } = item
        let alt = altPC
        const imagePCList = imagePC?.filter(f => f.size !== 'thumbnail') ?? []
        let imageMobileList = imageMobile?.filter(f => f.size !== 'thumbnail' && (f.size === 'default' || f.size === 'sm')) ?? []
        const imageMobileListLength = imageMobileList.length
        const isImageMobileHasDefaultOnly = imageMobileListLength === 1 && imageMobileList[0]?.size === 'default'
        if (isImageMobileHasDefaultOnly) {
          imageMobileList = cloneObject(imageMobileList).map((item) => {
            item.size = 'sm'
            return { ...item }
          })
        } else {
          imageMobileList = cloneObject(imageMobileList)?.filter(f => f.size !== 'default') ?? []
        }
        const onFoundExistingItem = () => {
          alt = altMobile
        }
        const list = mergeBannerImage(imagePCList.concat(imageMobileList), onFoundExistingItem)
        const newObj = {
          ...item,
          isMobile: this.$device.isMobile,
          bannerImage: {
            alt,
            images: list
          }
        }
        delete newObj.desktopImage
        delete newObj.mobileImage
        dataList.push(newObj)
      })
      this.bannerDataList = dataList
    },
    setBannerItemAttrLink,
    // sliderClicked (event) {
    //   if (
    //     !event.target.classList.contains('swiper-pagination-bullet') &&
    //     !event.target.classList.contains('swiper-button-next') &&
    //     !event.target.classList.contains('swiper-button-prev')
    //   ) {
    //     const swiper = this.$refs.heroBannerCarousel.swiper
    //     const { realIndex } = swiper
    //     const item = this.bannerList[realIndex]
    //     const url = item.url
    //     if (url) {
    //       const title = item.name
    //       this.$personalized.clickElement({
    //         name: 'home1',
    //         optionLang: {
    //           bannerItemName: title
    //         }
    //       })
    //     }
    //     this.$content.updateClickCounter(item.bannerItemUuid)
    //   }
    // },
    handleClickSlideItem (itemIndex) {
      // console.log('handleClickSlideItem', itemIndex)
      const item = this.bannerList[itemIndex] || {}
      const eventTracking = item.eventTracking || {}
      const isEtk = eventTracking.isEtk
      if (item.url && isEtk) {
        const title = item.name
        this.$personalized.clickElement({
          name: 'home1',
          optionLang: {
            bannerItemName: title
          }
        })
      }
      this.$content.updateClickCounter(item.bannerItemUuid)
    },
    swiperInit (swiper) {
      this.update()
      this.handleSwiperResize(swiper)
      swiper.on('resize', () => {
        this.handleSwiperResize(swiper)
      })
      this.loading = false
      this.manualLazyImage()
    },
    handleSwiperResize (swiper) {
      swiper.allowTouchMove = !swiper.isLocked
    },
    update () {
      const heroBannerCarousel = this.$refs.heroBannerCarousel
      if (heroBannerCarousel) {
        heroBannerCarousel.$el.style.minHeight = 'auto'
      }
    },
    manualLazyImage () {
      /* lazy loading */
      const slides = document.querySelectorAll('.hero-lazyload-trigger')
      function setDataImage (picture) {
        if (!picture) { return }
        const sourceList = picture.querySelectorAll('source')
        sourceList.forEach((item) => {
          if (item && item.getAttribute('data-srcset') && !item.getAttribute('srcset')) {
            item.setAttribute('srcset', item.getAttribute('data-srcset'))
          }
        })
        const img = picture.querySelector('img')
        if (img && img.getAttribute('data-src') && !img.getAttribute('src')) {
          img.setAttribute('src', img.getAttribute('data-src'))
        }
        picture.classList.add('intersection-ratio')
      }
      const observerSettings = {
        root: document.getElementById('heroBannerCarousel'),
        rootMargin: '0px',
        threshold: 1.0
      }

      if ('IntersectionObserver' in window) {
        const callback = (slides, observer) => {
          Array.prototype.forEach.call((slides), function (entry) {
            if (!entry.intersectionRatio > 0) {
              return
            }
            if (entry.isIntersecting && entry.target) {
              const picture = entry.target
              setDataImage(picture)
            }
          })
        }

        const observer = new IntersectionObserver(callback, observerSettings)
        Array.prototype.forEach.call(slides, t => observer.observe(t))
      } else {
        const slides = document.querySelectorAll('.hero-lazyload-trigger')
        if (slides) {
          slides.forEach((el) => {
            setDataImage(el)
          })
        }
      }
    }
  }
}
